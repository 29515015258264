import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
export default {
  data() {
    return {
      pageNo: null,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      activeTab: "all",
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "username",
        },
        {
          key: "plan_name",
        },
        {
          key: "transaction_uuid",
          label: "Transaction Id",
        },
        {
          key: "subscription_id",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
      ],
    };
  },
  methods: {
    search(event) {
      console.log(event);
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData() {
      this.$store.commit("loader/updateStatus", true);

      let request = `${indonesia_payment?.getSubscriptionHistory}`;

      request += `?page=` + this.currentPage;
      if (this.filter) {
        request += `&q=` + this.filter;
      }
      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res.status) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData();
        this.pageNo = this.currentPage;
      },
    },
  },
  created() {
    this.fetchData();
  },
};
